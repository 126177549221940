export class AristotleSearchResults extends HTMLElement {
    static get observedAttributes() {
        return ['query', 'current_so_id']
    }
    constructor() {
        super()
        this.filters = {}
        this.results = []
        this.workgroups = {}
        this.metadataWorkgroups = {}
        this.selectedFilters = {
            last_updated: '',
            type: '',
            stewardship_organisations: '',
            workgroups: '',
        }
        this.render()
        this.fetchComponentSetup()
    }

    connectedCallback() {
        this.setupListeners()
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue !== newValue) {
            if (name === 'query' && oldValue !== newValue) {
                this.fetchSearchResults()
            }

            if (name === 'current_so_id') {
                this.selectedFilters.stewardship_organisations = newValue  // Update selected filter
            }
        }
    }

    async fetchComponentSetup() {
        try {
            const response = await fetch("/api/internal/omnisearch/component-setup/")
            const data = await response.json()
            this.filters = data.filters
            this.renderFilters()
        } catch (error) {
            console.error("Error fetching component setup:", error)
        }
    }

    async fetchSearchResults() {
        const query = this.getAttribute('query')
        let { last_updated, type, stewardship_organisations, workgroups } = this.selectedFilters

        if (type === "help") {
            stewardship_organisations = ''
            workgroups = ''
        }

        const url = `/api/internal/omnisearch/search/?q=${encodeURIComponent(query || '')}&category=${type || ''}&mq=${last_updated || ''}&sa=${stewardship_organisations || ''}&wg=${workgroups || ''}`
        try {
            const resultsContainer = this.querySelector("#results")
            resultsContainer.innerHTML = '<div class="search-results-loader"></div>'  // Clear previous results and show loader
            this.toggleLoader(true)  // Show loader
            const response = await fetch(url)
            const data = await response.json()
            this.results = data.results
            this.workgroups = data.facets.workgroup || {}
            this.metadataWorkgroups = data.metadata.facets.workgroup || {}
            this.renderResults()
            this.renderWorkgroupFilter()
            this.selectedFilters.workgroups = ''
        } catch (error) {
            console.error("Error fetching search results:", error)
        } finally {
            this.toggleLoader(false)  // Hide loader after results are rendered
        }
    }

    toggleLoader(show) {
        const loader = this.querySelector(".search-results-loader")
        if (loader) loader.style.display = show ? "block" : "none"
    }

    setupListeners() {
        // Listener for radio buttons
        const filtersContainer = this.querySelector("#filters")
        filtersContainer.addEventListener('change', (event) => {
            const { name, value } = event.target
            if (name in this.selectedFilters) {
                this.selectedFilters[name] = value
                this.fetchSearchResults()
            }
        })
    }

    render() {
        this.innerHTML = `
        <div class="search-results-container">
            <div class="search-results-filters" id="filters">
                <!-- Filters will be dynamically inserted here -->
            </div>
            <div class="search-results-results" id="results">
                <div class="search-results-loader"></div> <!-- Loader element -->
            </div>
        </div>
        `
    }

    renderFilters() {
        const filtersContainer = this.querySelector("#filters")
        filtersContainer.innerHTML = `
          <div class="filter-section">
            <h4 class="result-header">Last Updated</h4>
            <div class="last-updated-filter"></div>
          </div>
          <div class="filter-section">
            <h4 class="result-header">Type</h4>
            <div class="type-filter"></div>
          </div>
          <div class="filter-section">
            <h4 class="result-header">Stewardship Organisations</h4>
            <div class="org-filter"></div>
          </div>
          <div class="filter-section workgroup-filter">
            <h4 class="result-header">Workgroups</h4>
            <div class="workgroup-options"></div>
          </div>
        `

        this.renderFilterSection(this.querySelector('.last-updated-filter'), this.filters.last_updated, 'last_updated')
        this.renderFilterSection(this.querySelector('.type-filter'), this.filters.type, 'type')
        this.renderFilterSection(this.querySelector('.org-filter'), this.filters.stewardship_organisations, 'stewardship_organisations')
    }

    renderFilterSection(container, items, filterType) {
        container.innerHTML = ''
        const currentSOId = this.getAttribute('current_so_id')

        items.forEach(item => {
            const label = document.createElement('label')
            label.setAttribute('class', 'label-style')
            let isChecked = false // Default value for checked state
            if (filterType === 'last_updated') {
                isChecked = item.label === 'Anytime' && item.value === ''
            } else if (filterType === 'type') {
                isChecked = item.label === 'All' && item.value === 'all'
            } else if (filterType === 'stewardship_organisations') {
                isChecked = (item.value === Number(currentSOId) || (currentSOId === "" && item.value === ""))
            }
            label.innerHTML = `<input type="radio" name="${filterType}" value="${item.value}" ${isChecked ? 'checked' : ''}>  ${item.label}`
            container.appendChild(label)
        })
    }

    renderWorkgroupFilter() {
        const container = this.querySelector('.workgroup-options')
        if (!container) {
            console.warn("Workgroup options container not found.")
            return
        }

        container.innerHTML = ''

        Object.entries(this.workgroups).forEach(([id, ]) => {
            const workgroupMeta = this.metadataWorkgroups[id]
            const displayName = workgroupMeta && typeof workgroupMeta === 'object' ? workgroupMeta.name : workgroupMeta || "No workgroup"
            const label = document.createElement('label')
            label.setAttribute('class', 'label-style')
            label.innerHTML = `<input type="radio" name="workgroups" value="${id}"> ${displayName}`
            container.appendChild(label)
        })
    }

    formatDate(dateString) {
        const date = new Date(dateString)
        const day = date.getDate()
        const month = date.toLocaleString('default', { month: 'long' })
        const year = date.getFullYear()

        // Determine the suffix for the day
        const suffix = (day) => {
            if (day > 3 && day < 21) return 'th' // Covers 11th to 13th
            switch (day % 10) {
                case 1: return 'st'
                case 2: return 'nd'
                case 3: return 'rd'
                default: return 'th'
            }
        }

        return `${day}${suffix(day)} ${month} ${year}`
    }
    navigateToMoreResults() {
        const query = this.getAttribute('query')
        let { last_updated, type, stewardship_organisations, workgroups } = this.selectedFilters
        const url = `/search?q=${encodeURIComponent(query || '')}&category=${type || ''}&mq=${last_updated || ''}&sa=${stewardship_organisations || ''}&wg=${workgroups || ''}`
        window.location.href = url
    }

    renderResults() {
        const resultsContainer = this.querySelector("#results")
        resultsContainer.innerHTML = ""  // Clear previous content before rendering new results

        const filteredResults = this.results.filter(result =>
            ["data", "references", "collaboration", "metadata", "help"].includes(result.category)
        )

        // Display the number of results if filteredResults is not empty
        if (filteredResults.length > 0) {
            resultsContainer.innerHTML += `<h4 class="result-header">Showing ${filteredResults.length} results</h4>`
        } else {
            resultsContainer.innerHTML += `
                <div class="search-results-no-results">
                    <span class="question-mark">?</span>
                    <h4>We couldn't find anything matching your search.</h4>
                    <div>Try again with a different term.</div>
                </div>
            `
            return
        }

        filteredResults.forEach(result => {
            const listItem = document.createElement('a')
            listItem.href = result.url
            let badgeText = ''
            if (result.category === "data" || result.category === "metadata") {
                badgeText = result.type.name.split(' ').map(word => word.charAt(0).toUpperCase()).join('')
            } else {
                badgeText = result.category.charAt(0).toUpperCase()
            }
            const restrictionIcon = (result.category === "data" || result.category === "metadata")
                ? (result.restriction === 0 ? '<i class="fa fa-eye" style="margin: 0 5px;"></i>' :
                result.restriction === 1 ? '<i class="fa fa-lock" style="margin: 0 5px;"></i>' :
                result.restriction === 2 ? '<i class="fa fa-unlock" style="margin: 0 5px;"></i>' : '')
                : ''

            const matchingOrg = this.filters.stewardship_organisations.find(org => org.value === result.stewardship_organisation)

            listItem.innerHTML = `
                <div>
                    ${badgeText ? `<span class="badge">${badgeText}</span> ` : ''}
                    ${restrictionIcon}
                    ${matchingOrg && matchingOrg.profile_picture_url ?
                        `<img src="${matchingOrg.profile_picture_url}" alt="Icon" style="width: 20px; height: 20px; vertical-align: middle; margin-right: 5px;">` : ''}
                    ${result.title}
                </div>
                <small>Last modified: ${result.modified ? this.formatDate(result.modified) : "N/A"}</small>
            `
            listItem.classList.add('search-results-result-item')

            resultsContainer.appendChild(listItem)
        })
            const seeMoreButton = document.createElement('button')
            seeMoreButton.textContent = 'See more results'
            seeMoreButton.classList.add('search-button')
            seeMoreButton.addEventListener('click', (event) => {
                event.preventDefault()
                this.navigateToMoreResults()
            })
            resultsContainer.appendChild(seeMoreButton)
    }

}
