export class AristotleSearchInitial extends HTMLElement {
    static get observedAttributes() {
        return ['current_so_id']
    }

    constructor() {
        super()
        // Directly append the container to this element, rather than using shadow DOM
        this.container = this.createContainer()
        this.appendChild(this.container)
        this.fetchData()
    }

    attributeChangedCallback(name, oldValue, newValue) {
        if (oldValue !== newValue) {
            this.fetchData() // Re-fetch data to apply the current_so_id filter
        }
    }

    createContainer() {
        const container = document.createElement('div')
        container.setAttribute('class', 'search-initial-container')
        return container
    }

    async fetchData() {
        try {
            const response = await fetch('/api/internal/omnisearch/component-initial/')
            const data = await response.json()
            this.render(data)
        } catch (error) {
            console.error('Error fetching data:', error)
        }
    }

    render(data) {
        this.container.innerHTML = ''
        const mainContainer = document.createElement('div')
        mainContainer.setAttribute('class', 'search-initial-main-containerr')
        const headerContainer = this.createHeader(data.stewardship_organisation)
        if (headerContainer) {
            mainContainer.appendChild(headerContainer)
        }
        const leftPart = this.createLeftPart(data.actions)
        const rightPart = this.createRightPart(data.history.search_history)
        const contentContainer = document.createElement('div')
        contentContainer.setAttribute('class', 'search-initial-content-container')
        contentContainer.appendChild(leftPart)
        contentContainer.appendChild(rightPart)
        mainContainer.appendChild(contentContainer)
        const advancedSearchButton = this.createAdvancedSearchButton()
        mainContainer.appendChild(advancedSearchButton)
        this.container.appendChild(mainContainer)
    }

    createHeader(stewardshipOrganisations) {
        const currentSoId = this.getAttribute('current_so_id')
        const currentSoIdInt = currentSoId ? parseInt(currentSoId) : null
        const matchingOrganisation = stewardshipOrganisations.find(
            org => org.id === currentSoIdInt
        )
        if (matchingOrganisation) {
            const headerContainer = document.createElement('div')
            headerContainer.setAttribute('class', 'search-initial-header-container')
            headerContainer.style.display = 'flex'
            headerContainer.style.alignItems = 'center'
            const orgName = document.createElement('h3')
            orgName.textContent = matchingOrganisation.name
            orgName.style.marginRight = '8px'
            const profileImg = document.createElement('img')
            profileImg.src = matchingOrganisation.profile_picture_url
            profileImg.alt = `${matchingOrganisation.name} Profile Picture`
            profileImg.setAttribute('class', 'profile-picture')
            profileImg.style.width = '28px'
            profileImg.style.height = '28px'
            profileImg.style.borderRadius = '50%'
            headerContainer.appendChild(profileImg)
            headerContainer.appendChild(orgName)
            return headerContainer
        }
        return null
    }

    createLeftPart(actions) {
        const leftPart = document.createElement('div')
        leftPart.setAttribute('class', 'search-initial-left-part')

        const currentSoId = this.getAttribute('current_so_id')
        const currentSoIdInt = currentSoId ? parseInt(currentSoId) : null

        const actionKeys = Object.keys(actions)

        actionKeys.forEach(action => {
            const actionSection = document.createElement('div')
            const sectionHeader = document.createElement('h4')
            sectionHeader.classList.add('aristotle-search-initial-header')

            sectionHeader.textContent = action.replace(/_/g, ' ').replace(/^./, str => str.toUpperCase())
            actionSection.appendChild(sectionHeader)

            const actionList = document.createElement('div')
            actionList.setAttribute('class', 'search-initial-action-list')

            const filteredItems = actions[action].filter(item =>
                currentSoIdInt ? item.so_id === currentSoIdInt : true
            )

            if (filteredItems.length > 0) {
                filteredItems.forEach(item => {
                    const itemLink = document.createElement('a')
                    itemLink.setAttribute('class', 'search-initial-result-item')
                    itemLink.setAttribute('href', item.url)
                    itemLink.innerHTML = `
                        <span class="search-initial-icon-container">${item.icon || ''}</span> ${item.name}
                        ${action === 'recently_viewed_metadata' && item.item_type_name ? `<small>(${item.item_type_name})</small>` : ''}
                    `
                    actionList.appendChild(itemLink)
                })
            } else {
                const emptyMessage = document.createElement('div')
                emptyMessage.setAttribute('class', 'search-initial-result-item')
                emptyMessage.textContent = `No ${action.replace(/_/g, ' ').replace(/^./, str => str.toUpperCase())}`
                actionList.appendChild(emptyMessage)
            }

            actionSection.appendChild(actionList)
            leftPart.appendChild(actionSection)
        })

        return leftPart
    }

    createRightPart(searchHistory) {
        const rightPart = document.createElement('div')
        rightPart.setAttribute('class', 'search-initial-right-part')

        const historyHeader = document.createElement('h4')
        historyHeader.classList.add('aristotle-search-initial-header')
        historyHeader.textContent = 'Search History'
        rightPart.appendChild(historyHeader)

        this.renderSearchHistory(searchHistory, rightPart)
        return rightPart
    }

    renderSearchHistory(searchHistory, rightPart) {
        if (searchHistory.length) {
            searchHistory.forEach(item => {
                if (item.search_text) {
                    const searchItem = document.createElement('div')
                    searchItem.setAttribute('class', 'search-initial-result-item')
                    searchItem.innerHTML = `
                        <span class="icon-container">${item.icon || ''}</span> ${item.search_text}
                    `
                    searchItem.addEventListener('click', () => {
                        window.location.href = `/account/recently_searched/${item.id}`
                    })
                    rightPart.appendChild(searchItem)
                }
            })
        }
    }

    createAdvancedSearchButton() {
        const advancedSearchButton = document.createElement('button')
        advancedSearchButton.textContent = 'Advanced Search'
        advancedSearchButton.setAttribute('class', 'search-button')
        advancedSearchButton.addEventListener('click', (event) => {
            event.preventDefault()
            window.location.href = '/search/'
        })
        return advancedSearchButton
    }
}
